.noUi-target {
  background-color: #fff;
  border: none;
}

.noUi-connect {
  background: #0b4566;
}

.noUi-handle {
  background: #0b4566;
  border: none;
  box-shadow: none;
  border-radius: 7px;
  cursor: pointer;
}

.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  top: -5px;
}
.noUi-handle:focus {
  outline: 0;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
