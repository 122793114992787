.react-geocoder input {
  width: 248px;
  height: 34px;
  border: none;
  color: #0b4566;
}
.react-geocoder input:focus {
  outline: none;
}

.react-geocoder-results {
  width: 240px;
  padding: 4px;
  background: #fff;
  font-family: 'Roboto';
  color: #0b4566;
}
