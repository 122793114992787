.datepicker__wrapper {
  box-sizing: border-box;
}

.datepicker__wrapper--month,
.datepicker__wrapper--year {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 2px;
}

.datepicker__wrapper--year {
  padding-top: 8px;
}

.datepicker__month {
  width: calc(16.6% - 4px);
}

.datepicker__year {
  width: calc(16.6667% - 4px);
}

.datepicker__year--title {
  margin: 0px 8px;
}

.datepicker__checkbox {
  width: 6px;
  height: 6px;
  background: #86a2b3;
  display: inline-block;
  margin: 0px 2px;
}

.datepicker__checkboxSelected {
  background: #0b4566;
}

.datepicker__month {
  width: calc(16.6667% - 4px);
  margin: 2px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.datepicker__monthTitle {
  font-size: 10px;
  width: 20px;
  margin: 0px;
  display: inline-block;
  color: #86a2b3;
}

.datepicker__monthTitleSelected {
  color: #0b4566;
}

.datepicker__year {
  height: 16px;
  text-align: center;
  background: #f2f5f7;
  border-radius: 8px;
  margin: 2px;
  cursor: pointer;
}

.datepicker__month,
.datepicker__year p {
  font-size: 10px;
}

.datepicker__range {
  background-color: #cfdbe2;
}

.datepicker__year:hover,
.datepicker__selected {
  background-color: #0b4566;
}

.datepicker__year:hover p,
.datepicker__selected p {
  color: #fff;
}
